import { render, staticRenderFns } from "./GroupDisplay.vue?vue&type=template&id=6b21a9e1&"
import script from "./GroupDisplay.vue?vue&type=script&lang=js&"
export * from "./GroupDisplay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports